import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  Div,
  Label,
  Button,
  CheckDiv,
  CheckBox,
  StyledRegisterModal,
} from "./style";
import { useDispatch, useSelector } from "react-redux";

import { FormGroup } from "react-bootstrap";
import TextInput from "components/formComponents/textInput";
import Routes from "constants/routes";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { apiHandler } from "services/axios";
import { getUserNameApi } from "services/userServices";
import { login } from "store/loginStore/actions";

const FORM_FILEDS = {
  name: "name",
  username: "username",
};

const validationSchema = Yup.object().shape({
  [FORM_FILEDS.name]: Yup.string().required("Name is required"),
  [FORM_FILEDS.username]: Yup.string().required("Username is required"),
});

function WalletForm({ show, onHide, ...restProps }) {
  const userData = useSelector((state) => state.user);
  const formRef = useRef(null);
  const {
    account: address,
    signature,
    message,
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleSignIn = async (values) => {
    const { name, username } = values;
    console.log({ signature, address, name, username });
    try {
      dispatch(
        login({ username, name, signature, message, account: address })
      ).then((res) => {
        hideModal();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const hideModal = () => {
    onHide && onHide();
  };

  const usernameChangeHandler = (e) => {
    const username = e.target.value;
    console.log(username);
    if (formRef) {
      formRef.current.setFieldTouched(FORM_FILEDS.username, true, false);
      formRef.current.setFieldValue(FORM_FILEDS.username, username);
      apiHandler(() => getUserNameApi(username), {
        onSuccess: (data) => {
          if (data?.status === "success") {
            formRef.current.setFieldError(FORM_FILEDS.username, "");
          } else {
            formRef.current.setFieldError(
              FORM_FILEDS.username,
              "Username is not available"
            );
          }
        },
        onError: (error) => {
          console.log("error", error);
          formRef.current.setFieldError(
            FORM_FILEDS.username,
            "Username is not available"
          );
        },
      });
    }
  };

  return (
    <StyledRegisterModal
      show={show}
      onHide={onHide}
      showCloseButton
      {...restProps}
    >
      <h2 className="form-title">Create Account</h2>

      <div className="address-wrapper">
        <p className="address">{address}</p>
      </div>
      <Formik
        initialValues={{
          [FORM_FILEDS.name]: "",
          [FORM_FILEDS.username]: "",
        }}
        validationSchema={validationSchema}
        innerRef={formRef}
        onSubmit={handleSignIn}
      >
        {({ values, errors, handleChange, handleBlur }) => {
          return (
            <FormikForm>
              <FormGroup>
                {console.log(errors)}
                <TextInput
                  helperText="Enter Your Full Name"
                  autoComplete="off"
                  error={errors[FORM_FILEDS.name]}
                  value={values[FORM_FILEDS.name]}
                  name={FORM_FILEDS.name}
                  placeholder="Name"
                  onChange={handleChange}
                />{" "}
              </FormGroup>
              <FormGroup>
                <TextInput
                  helperText="Enter Your UserName"
                  autoComplete="off"
                  placeholder="Username"
                  name={FORM_FILEDS.username}
                  // error={errors[FORM_FILEDS.username]}
                  value={values[FORM_FILEDS.username]}
                  onChange={usernameChangeHandler}
                />
              </FormGroup>

              <p className="footer-message">
                Please take a few minutes to read and understand{" "}
                <NavLink to={Routes.termsOfUse}>Sosh Terms of Service.</NavLink>{" "}
                To continue, you’ll need to accept the Terms of Service by
                checking the box.
              </p>
              <br />
              <Div>
                <CheckDiv>
                  {" "}
                  {/* <Div> */}
                  <Label>I am at least 13 year old</Label>{" "}
                  <CheckBox
                    //   autoComplete="off"
                    //   name="isAgevalid"
                    //  ref={register({ required: true })}
                    type="checkbox"
                  />
                  {/*  */}
                  {/* <br /> */}
                </CheckDiv>
                <CheckDiv>
                  {" "}
                  <Label>I accept the Sosh Terms of Service</Label>{" "}
                  <CheckBox
                    // autocomplete="off"
                    // name="name"
                    // onChange={handleChange}
                    // ref={register({ required: true })}
                    type="checkbox"
                  />
                  {/* </Div> */}
                </CheckDiv>
              </Div>
              {/* <br /> */}
              <Button type="submit">Create Account</Button>
            </FormikForm>
          );
        }}
      </Formik>
    </StyledRegisterModal>
  );
}

export default WalletForm;
WalletForm.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};
