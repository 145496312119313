export const STORAGES = {
  token: "token",
  id: "id",
};

export const PalleteColorTypes = [
  "primary",
  "secondary",
  "tertiary",
  "white",
  "black",
];
