import { STORAGES } from "constants/appConstants";
import { getLocalStorageItem } from "./helpers/localStorageHelpers";
import jwtDecode from "jwt-decode";

// a function to check if jwt token is expired or not
export const isJwtExpired = (token) => {
  if (typeof token !== "string" || !token)
    throw new Error("Invalid token provided");

  let isJwtExpired = false;
  const { exp } = jwtDecode(token);
  const currentTime = new Date().getTime() / 1000;

  if (currentTime > exp) isJwtExpired = true;

  return isJwtExpired;
};

export const checkIfLogin = () => {
  const token = getLocalStorageItem(STORAGES.token);
  return !!token && !isJwtExpired(token);
};
