import axios from "axios";
var FormData = require("form-data");

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// const URL = "http://localhost:5000/api";
const URL = "https://sosh-nft.oodleslab.com/api";

export const getUserNameApi = (userName) => {
  console.log(userName, "userName");
  var config = {
    method: "get",
    url: URL + `/V1/user/checkusername?username=${userName}`,
    headers: {},
  };
  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const twitterLoginApi = (token) => {
  var config = {
    method: "get",
    url: URL + `/V1/social/twitter/login?auth_token=${token}`,
    headers: {
      Cookie:
        "connect.sid=s%3Am9p_6q4c-avYfeY1jW42U3T0h-cq8Yz2.MAN2vqq8dqH03%2BUPG92e%2FcTc8LpScl%2BcCJNCq6T7TuE",
    },
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const updateUserApi = (data, token) => {
  var data = JSON.stringify({
    name: data.name,
    youtubeUsername: data.username,
    tiktokUsername: data.tictok,
  });

  var config = {
    method: "post",
    url: URL + "/V1/user/updateUser",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const allPostsApi = (data, token) => {
  var config = {
    method: "get",
    url: URL + "/V1/assets/owner/61e3e1bb69282c675bd499e8",
    headers: {},
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });

  var axios = require("axios");

  var config = {
    method: "get",
    url: URL + "/V1/assets?platform_type=TWITTER",
    headers: {},
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const profilePostApi = (data, token) => {
  var config = {
    method: "get",
    url: URL + "/V1/assets?platform_type=TWITTER",
    headers: {},
  };

  return axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      // return error;
      console.log(error);
    });
};

export const uploadProfileApi = (img, token) => {
  console.log("uploadProfileApi");
  var data = new FormData();
  data.append("image", img);

  var config = {
    method: "post",
    url: URL + "/V1/user/updateprofileimage",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const deleteProfileApi = (token) => {
  var config = {
    method: "post",
    url: URL + "/V1/user/removeprofileimage",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const validateLinkApi = (url, token) => {
  var data = JSON.stringify({
    link: url,
  });

  var config = {
    method: "post",
    url: URL + "/V1/social/twitter/validatelink",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getMessageApi = (userName) => {
  var config = {
    method: "get",
    url: URL + `/V1/auth/message`,
    headers: {},
  };
  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getUserApi = (address) => {
  const add = address.toLowerCase();
  var config = {
    method: "get",
    url: URL + `/V1/user/getUserByWalletAddress/${add}`,
    headers: {},
  };
  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const createAssetsApi = (data, token, uid) => {
  console.log(data, token, uid);
  var data = JSON.stringify({
    hashtag: data.hashtag,
    post_url: uid.Validate_URL,
    platform_type: "TWITTER",
    description: data.caption,
    metadata_url: "https://ipfs.io/ipfs/" + uid.CID.cid,
    name: data.title,
  });

  var config = {
    method: "post",
    url: URL + "/V1/assets",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const metaDataApi = (url, token) => {
  var data = JSON.stringify({
    metadata: {
      name: "Kuch bhi",
      description: "kuch bhi",
      image: url,
    },
  });

  var config = {
    method: "post",
    url: URL + "/V1/ipfs/uploadmetadata",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response;
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};
