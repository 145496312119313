import React from "react";

import { Routes as RouteSwitch, Route } from "react-router-dom";
import Routes from "constants/routes";

const Body = React.lazy(() => import("components/body/body"));

const HomePage = React.lazy(() => import("pages/homePage/homePage"));
const DetailsPage = React.lazy(() => import("pages/detailPage/detailPage"));
const ProfilePage = React.lazy(() => import("pages/myProfile/myProfile"));
const NewPost = React.lazy(() => import("pages/newPost/newPost"));
const ProfileEditPage = React.lazy(() =>
  import("pages/editProfile/editProfile")
);
const NFTCreatePage = React.lazy(() => import("pages/createNFT/createNFT"));
const DeletionPage = React.lazy(() =>
  import("pages/deletionPage/deletionPage")
);
const TermsOfUsePage = React.lazy(() => import("pages/termsOfUse/termsOfUse"));
const PrivacyPolicyPage = React.lazy(() =>
  import("pages/privacyPolicy/privacyPolicy")
);

function AppRouter() {
  return (
    <RouteSwitch>
      <Route exact path={Routes.home} element={<HomePage />}></Route>
      <Route exact path={Routes.profile} element={<ProfilePage />}></Route>
      <Route
        exact
        path={Routes.termsOfUse}
        element={<TermsOfUsePage />}
      ></Route>
      <Route
        exact
        path={Routes.privacyPolicy}
        element={<PrivacyPolicyPage />}
      ></Route>
      <Route
        exact
        path={Routes.deletionDetails}
        element={<DeletionPage />}
      ></Route>
      <Route
        exact
        path={Routes.editProfile}
        element={<ProfileEditPage />}
      ></Route>
      <Route exact path={Routes.createNFT} element={<NFTCreatePage />}></Route>
      <Route exact path="/home" element={<Body />}></Route>
      <Route exact path={Routes.newPost} element={<NewPost />}></Route>
      <Route exact path={Routes.nftDetail} element={<DetailsPage />}></Route>
    </RouteSwitch>
  );
}

export default AppRouter;
