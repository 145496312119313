import {
  SET_CHAIN_ID,
  SET_HAS_METAMASK,
  SET_PROVIDER,
  SET_WEB3_INSTANCE,
} from "./actionTypes";

export const setWeb3Instance = (web3Instance) => {
  return {
    type: SET_WEB3_INSTANCE,
    payload: web3Instance,
  };
};

export const setChainId = (chainId) => {
  return {
    type: SET_CHAIN_ID,
    payload: chainId,
  };
};

export const setHasMetamask = (hasMetamask) => {
  return {
    type: SET_HAS_METAMASK,
    payload: hasMetamask,
  };
};

export const setProvider = (provider) => {
  return {
    type: SET_PROVIDER,
    payload: provider,
  };
};
