import React from "react";
import { ModalBody } from "react-bootstrap";

import PropTypes from "prop-types";
import { StyledModalWrapper } from "./style";
import CloseIcon from "assets/icons/closeIcon";

const ModalWrapper = ({
  children,
  fillColor = "black",
  showCloseButton = false,
  dialogWidth,
  ...props
}) => {
  const { onHide } = props;
  return (
    <StyledModalWrapper
      $dialogWidth={dialogWidth}
      {...props}
      contentClassName="styled-modal-content"
    >
      <ModalBody>
        {showCloseButton && (
          <div
            className="close-button-container"
            onClick={onHide}
            role="button"
            tabIndex="0"
            onKeyDown={() => {}}
          >
            <CloseIcon className="close-button" fillColor={fillColor} />
          </div>
        )}
        {children}
      </ModalBody>
    </StyledModalWrapper>
  );
};

ModalWrapper.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  width: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  showCloseButton: PropTypes.bool,
  fillColor: PropTypes.string,
  dialogWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ModalWrapper;
