import styled from "styled-components";
import { Modal as RectBootstrapModal } from "react-bootstrap";
import { deviceQuery } from "styles/mediaSizes";
import { convertPxToRem } from "common/helpers";

export const StyledModalWrapper = styled(RectBootstrapModal)`
  .modal-dialog {
    max-width: ${`calc(100% - ${convertPxToRem(20)})`};
    margin-right: auto;
    margin-left: auto;

    .modal-content {
      padding: ${`${convertPxToRem(15)} ${convertPxToRem(20)}`};
      color: ${(props) => props.theme.palette.text.dark};

      @media ${deviceQuery.tablet} {
        padding: ${`${convertPxToRem(5)} ${convertPxToRem(10)}`};
      }

      .modal-body {
        padding: ${(props) =>
          props.padding
            ? props.padding
            : `${convertPxToRem(15)} ${convertPxToRem(5)}`};
        text-align: center;
        width: ${(props) => (props.width ? props.width : "100%")};
        margin: auto;

        .close-button-container {
          position: absolute;
          top: 0;
          right: 0;
          width: ${convertPxToRem(20)};
          height: ${convertPxToRem(20)};
          text-align: center;

          cursor: pointer;
          flex-shrink: 0;
          .close-button {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
`;
