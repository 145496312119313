import React, { useEffect } from "react";
import PropTypes from "prop-types";

const EllipsePositions = {
  start: "start",
  end: "end",
  middle: "middle",
};

const getEllipsedText = ({ text, maxLength, ellipseText, ellipsePosition }) => {
  if (text.length > maxLength) {
    switch (ellipsePosition) {
      case EllipsePositions.start:
        return `${ellipseText}${text.slice(text?.length - maxLength)}`;
      case EllipsePositions.end:
        return `${text.slice(0, maxLength)}${ellipseText}`;
      case EllipsePositions.middle:
        return `${text.slice(0, maxLength / 2)}${ellipseText}${text.slice(
          text?.length - maxLength / 2
        )}`;
      default:
        return text;
    }
  }
  return text;
};

function EllipsedText({
  text: textProp,
  maxLength = 10,
  ellipsePosition = EllipsePositions.end,
  ellipseText = "...",
}) {
  const [text, setText] = React.useState("");

  useEffect(() => {
    if (textProp?.length > maxLength) {
      const TempEllipsedText = getEllipsedText({
        text: textProp,
        maxLength,
        ellipseText,
        ellipsePosition,
      });

      setText(TempEllipsedText);
    } else {
      setText(textProp);
    }
  }, [textProp, maxLength, ellipsePosition, ellipseText]);

  return <span className="ellipsed-text">{text}</span>;
}

export default EllipsedText;

EllipsedText.propTypes = {
  text: PropTypes.string,
  maxLength: PropTypes.number,
  ellipsePosition: PropTypes.oneOf(Object.values(EllipsePositions)),
  ellipseText: PropTypes.string,
};
