const defaultConfig = {
  apiBaseUrl: "https://sosh-nft.oodleslab.com/api/V1",
  // apiBaseUrl: "http://localhost:5000/api",
  cacheRefetchTime: 1000 * 60 * 30,
  cacheVersion: 1, // should be equal to our app version
  web3ProviderFallbackUrl: "http://localhost:8545",
};

const getConfig = () => {
  const config = {
    ...defaultConfig,
    ...(window.__CONFIG__ || {}),
  };
  return config;
};

export default getConfig;
