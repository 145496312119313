import React from "react";
import PropTypes from "prop-types";
import { StyledTextInput } from "./style";
import { FormControl, FormLabel } from "react-bootstrap";

const TextInput = ({ label, id, name, helperText, error, ...restProps }) => {
  return (
    <>
      <StyledTextInput className="text-input-wrap">
        {label && (
          <FormLabel htmlFor={id ?? name} className="text-input-label">
            {label}
          </FormLabel>
        )}
        <FormControl
          id={id ?? name}
          name={name}
          isInvalid={error}
          bsPrefix="custom-text-input"
          {...restProps}
        />

        {!error && helperText && (
          <span className="text-helper">{helperText}</span>
        )}

        <FormControl.Feedback type={"invalid"}>{error}</FormControl.Feedback>
      </StyledTextInput>
    </>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  error: PropTypes.string,
  ...FormControl.propTypes,
};

export default TextInput;
