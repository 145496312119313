import getAxiosInst from "./axios";

const API_SUB_ENDPOINT = "/user";

export const getUserApi = (address) => {
  const add = address.toLowerCase();

  return getAxiosInst().get(
    `${API_SUB_ENDPOINT}/getUserByWalletAddress/${add}`
  );
};

export const getUserNameApi = (userName) => {
  console.log(userName, "userName");
  return getAxiosInst().get(
    `${API_SUB_ENDPOINT}/checkusername?username=${userName}`
  );
};

// export const updateUserApi = (data, token) => {
//   var data = JSON.stringify({
//     name: data.name,
//     youtubeUsername: data.username,
//     tiktokUsername: data.tictok,
//   });

//   var config = {
//     method: "post",
//     url: URL + "/V1/user/updateUser",
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//     data: data,
//   };

//   axios(config)
//     .then(function (response) {
//       console.log(JSON.stringify(response.data));
//     })
//     .catch(function (error) {
//       console.log(error);
//     });
// };
