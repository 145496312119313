import styled from "styled-components";

export const Main = styled.div`
  over-flow: visible;
  position: inherit;
  background: white;
  width: 20rem;
  overflow: visible;
  margin-top: 2rem;
  float: right;
  a{
    text-decoration: none;
  }
}
`;
export const Items = styled.div`
  //   margin-left: 5px;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  font-size: 0.85rem;
  color: gray;
`;
export const ItemSec = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  //   justify-content: space-between;
  //   font-size: 12px;
`;
export const HeadDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 2.813rem;
  padding: 0 0.625rem;
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *,
  :after,
  :before {
    box-sizing: border-box;
  }
`;
export const MainDesc = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
`;
export const UserName = styled.span`
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.15s ease-in;
`;
export const UserSpan = styled.span`
  // font-family: Inter, sans-serif;
  font-size: 0.813rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.3px;
  text-align: left;
  color: #2c2a38;
  margin-bottom: 3px;
  width: 100%;
  display: flex;
  margin-left: 0.813rem;
`;
export const UserDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 2rem);
`;
export const ProfileImg = styled.div`
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  // width: 25px;
  border-radius: 50%;
  position: relative;
  background: url(https://app.nafter.io/static/media/defaultAvatar.2bd8b1b6.svg)
    no-repeat 50% / cover;
`;
export const ProfileDesc = styled.span`
  // font-family: Inter, sans-serif;
  font-size: 0.625rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.3px;
  text-align: left;
  color: #5a5a63;
  display: block;
  margin-left: 0.813rem;
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.15s ease-in;
`;
export const ButtonSec = styled.div`
  font-size: 14px;
  padding: 0 0.625rem;
`;
export const SocialSec = styled.div`
  background: linear-gradient(90deg, #005bea -2.59%, #00c6fb 160.54%),
    linear-gradient(96.76deg, #ffa17f -44.56%, #00223e 189.36%), #c4c4c4;
  color: #ffffff;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
`;

export const NameSpan = styled.span`
  margin-left: 5px;
  color: black;
  text-decoration: none;
`;
export const AddressImag = styled.img`
  width: 1.5rem;
`;
export const AddressSec = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
export const Balance = styled.span`
  color: gray;
  margin-right: 5px;
`;

export const Image = styled.img`
  width: 1rem;
  margin-right: 0.3rem;
`;

export const Div = styled.div`
  line-height: 2rem;
`;
