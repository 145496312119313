import React, { useEffect, useState } from "react";
import {
  LogoDiv,
  Main,
  Button,
  ButtonHollow,
  InputImage,
  Input,
  InputLable,
  RigntSection,
  Mobile,
  ButtomHover,
  MolSection,
} from "./style";
import { toast } from "react-toastify";
import { ethers } from "ethers";

import Web3 from "web3";
import { Link } from "react-router-dom";
import CardModel from "../connectModal/connectModal";
import SearchTab from "../searchTab/searchTab";
import WalletForm from "../walletFormModal/walletForm";
import { useDispatch, useSelector } from "react-redux";
import Routes from "constants/routes";
import { getChainId } from "common/helpers/web3Helpers";
import { setChainId } from "store/commonStore/actionCreator";
import {
  getSignInMsg,
  login,
  sendSignatureRequest,
} from "store/loginStore/actions";
import EllipsedText from "components/EllipsedText";
import { apiHandler } from "services/axios";
import { getUserApi } from "services/userServices";
import { setUserData } from "store/userStore/actionCreators";
import { setLogin } from "store/loginStore/actionCreator";
import { loginToLocalStorage } from "common/helpers/localStorageHelpers";

const Header = () => {
  const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

  const [open, setOpen] = useState(false);

  const [registerFormVisibility, setRegisterFormVisibility] = useState(false);
  const [signin, setSignin] = useState(false);
  const data = useSelector((state) => state.data);
  const { hasMetamask, web3Instance } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const showRegisterForm = () => {
    setRegisterFormVisibility(true);
  };

  const hideRegisterForm = () => {
    setRegisterFormVisibility(false);
  };

  const handleDisplay = () => {
    setOpen(!open);
  };

  useEffect(() => {
    console.log(!data.userData.data, "signup check");

    if (!data.userData.data) {
      setSignin(true);
    } else {
      setSignin(false);
    }
  }, [data]);

  const { isLogin, address } = useSelector((state) => state.login);

  const handleAutoLogin = async (message, address, data) => {
    try {
      if (!window.ethereum) {
        throw new Error("Wallet not found");
      }
      await window.ethereum.send("eth_requestAccounts");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signature = await signer.signMessage(message);
      console.log("signature", message, signature);
      if (data.data.username) {
        dispatch(
          login({
            username: data.data.username,
            name: data.data.name,
            signature,
            message,
            address,
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const walletConnectHandler = async () => {
    if (hasMetamask) {
      const curChainId = await getChainId(web3Instance);
      dispatch(setChainId(curChainId));
      dispatch(getSignInMsg());
      dispatch(
        sendSignatureRequest({
          onRequestSuccess: ({ address, signature, message }) => {
            apiHandler(() => getUserApi(address), {
              onSuccess: (result) => {
                const { name, usrname } = result.data;
                dispatch(
                  login({ account: address, signature, message, name, usrname })
                );
              },
              onError: (error) => {
                showRegisterForm();
              },
            });
          },
        })
      );
    } else {
      console.log("metamask not found");
    }
  };

  return (
    <>
      <Main
        className="app-header"
        style={{ justifyContent: "space-between", display: "flex" }}
      >
        <div style={{ display: "flex", marginLeft: "71px" }}>
          <Link to="/">
            {" "}
            <LogoDiv></LogoDiv>{" "}
          </Link>
        </div>
        <RigntSection
          className="right-sec"
          style={{ marginRight: "91px", display: "flex" }}
        >
          <ButtomHover>
            <InputLable>
              <InputImage
                src="https://app.nafter.io/static/media/search.2752781f.svg"
                alt=""
              />
              <Input type="search" placeholder="Search..." value="" />
            </InputLable>
          </ButtomHover>
          <ButtomHover>
            {!isLogin ? (
              <Button style={{ width: "126px" }} onClick={walletConnectHandler}>
                Connect Wallet
              </Button>
            ) : (
              <>
                <Button style={{ width: "126px" }}>
                  <EllipsedText text={address} ellipsePosition="middle" />
                </Button>
                <div className="card-modal">
                  <CardModel />
                </div>
              </>
            )}
          </ButtomHover>
          <Link to={Routes.createNFT}>
            {" "}
            <ButtonHollow
              style={{ justifyContent: "space-between", width: "126px" }}
            >
              New Post
            </ButtonHollow>
          </Link>{" "}
        </RigntSection>
        <Mobile onClick={handleDisplay}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </Mobile>
      </Main>
      {open ? (
        <MolSection className="responsiv">
          <InputLable style={{ width: "100%" }}>
            <InputImage
              src="https://app.nafter.io/static/media/search.2752781f.svg"
              alt=""
            />
            <Input type="search" placeholder="Search..." value="" />
          </InputLable>
          <br />
          <br />
          <Button style={{ width: "100%" }} onClick={walletConnectHandler}>
            {data.sAddress != "" ? data.sAddress : "Connect Wallet"}
          </Button>{" "}
          <br />
          <br />{" "}
          <Link to="/edit-profile">
            <ButtonHollow style={{ width: "100%" }}>New Post</ButtonHollow>
          </Link>
        </MolSection>
      ) : (
        ""
      )}

      <WalletForm
        centered
        show={registerFormVisibility}
        onHide={hideRegisterForm}
      />
    </>
  );
};

export default Header;
