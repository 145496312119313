import React, { Suspense } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { LoopCircleLoading } from "react-loadingg";
import { setAddresses } from "store/Actions/data";
import { useDispatch } from "react-redux";

import AppRouter from "routers/appRouter";
import { checkIfLoginAndUpdateState, logout } from "store/loginStore/actions";
import { updateAccountDetailsOnChainChange } from "store/commonStore/actions";
import Header from "components/header/header";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    Aos.init({ duration: 2000 });
    dispatch(checkIfLoginAndUpdateState());
  }, [dispatch]);

  useEffect(() => {
    // registering event listener for metamask account change
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        dispatch(logout());
      });
      window.ethereum.on("chainChanged", (chainId) => {
        dispatch(updateAccountDetailsOnChainChange(chainId));
      });
    }
  }, [dispatch]);

  useEffect(() => {
    const sAdd = localStorage.getItem("short_address");
    const fAdd = localStorage.getItem("full_address");
    dispatch(setAddresses(fAdd, sAdd));
  }, []);

  return (
    <div
      className="App"
      // style={{ backgroundColor: "gainsboro", height: "40rem" }}
    >
      <Suspense fallback={<LoopCircleLoading />}>
        <Header />
        <section className="app-body">
          <AppRouter />
        </section>
      </Suspense>
    </div>
  );
}

export default App;
