const Routes = {
  home: "/",
  profile: "/my-profile",
  termsOfUse: "/terms-of-use",
  privacyPolicy: "/privacy-policy",
  deletionDetails: "/deletion-details",
  editProfile: "/edit-profile",
  createNFT: "/create-NFT",
  newPost: "/new-post",
  nftDetail: "/nft-detail",
};

export default Routes;
