import { convertPxToRem } from "common/helpers";
import styled from "styled-components";

export const StyledTextInput = styled.div`
  .text-input-label {
  }

  .custom-text-input {
    outline: none;
    height: ${convertPxToRem(40)};
    width: 100%;
    border: none;
    border-bottom: 1px solid;
    background: none;
    font-size: ${convertPxToRem(16)};
    line-height: 150%;
    color: ${(props) => props.theme.palette.text.primary};
    margin-top: 0.625rem;

    ::placeholder {
      color: ${(props) => props.theme.palette.text.primary};
      opacity: 1; /* Firefox */
    }
  }

  .text-helper {
    font-size: ${convertPxToRem(14)};
    line-height: 150%;
    text-align: inherit;
    color: ${(props) => props.theme.palette.text.secondary};
    margin-top: ${convertPxToRem(10)};
  }

  .text-input-error {
  }
`;
