import {
  SET_CHAIN_ID,
  SET_HAS_METAMASK,
  SET_PROVIDER,
  SET_WEB3_INSTANCE,
} from "./actionTypes";

const initialCommonState = {
  web3Instance: null,
  hasMetamask: Boolean(window.web3),
  chainId: null,
  provider: null,
};

export const commonReducer = (state = initialCommonState, action = {}) => {
  switch (action.type) {
    case SET_WEB3_INSTANCE:
      return {
        ...state,
        web3Instance: action.payload,
      };

    case SET_CHAIN_ID:
      return {
        ...state,
        chainId: action.payload,
      };

    case SET_HAS_METAMASK:
      return {
        ...state,
        hasMetamask: action.payload,
      };

    case SET_PROVIDER:
      return {
        ...state,
        provider: action.payload,
      };

    default:
      return { ...state };
  }
};
