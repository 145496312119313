import React, { useState } from "react";
import {
  Main,
  Item,
  Tab,
  Container,
  HeadDiv,
  MainDesc,
  ProfileImg,
  UserDiv,
  UserSpan,
  UserName,
  ProfileDesc,
} from "./style";
function SearchTab() {
  const [active, setActive] = useState({ edit: "active" });

  const handleTabItem = (active) => {
    if (active === "edit") {
      setActive({ edit: "active" });
    }
    if (active === "profile") {
      setActive({ profile: "active" });
    }
    if (active === "notifictaion") {
      setActive({ notifictaion: "active" });
    }
    console.log(active);
  };
  const user = () => {
    return (
      <HeadDiv>
        <MainDesc>
          <ProfileImg
            style={{
              backgroundImage: `url("https://d1zjvcsgfckfe5.cloudfront.net/crypto/1639869886407-Screenshot-20211218-221757-2.png")`,
            }}
          ></ProfileImg>
          <UserDiv>
            <UserSpan>
              <UserName>woodburning</UserName>
            </UserSpan>
            <ProfileDesc> Turkey</ProfileDesc>
          </UserDiv>
          <div>
            <div>
              <span style={{ float: "right" }}>
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-three-dots"
                  viewBox="0 0 16 16"
                >
                  <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                </svg>
              </span>
              <span style={{ float: "right", marginRight: "5px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-suit-heart"
                  viewBox="0 0 16 16"
                >
                  <path d="m8 6.236-.894-1.789c-.222-.443-.607-1.08-1.152-1.595C5.418 2.345 4.776 2 4 2 2.324 2 1 3.326 1 4.92c0 1.211.554 2.066 1.868 3.37.337.334.721.695 1.146 1.093C5.122 10.423 6.5 11.717 8 13.447c1.5-1.73 2.878-3.024 3.986-4.064.425-.398.81-.76 1.146-1.093C14.446 6.986 15 6.131 15 4.92 15 3.326 13.676 2 12 2c-.777 0-1.418.345-1.954.852-.545.515-.93 1.152-1.152 1.595L8 6.236zm.392 8.292a.513.513 0 0 1-.784 0c-1.601-1.902-3.05-3.262-4.243-4.381C1.3 8.208 0 6.989 0 4.92 0 2.755 1.79 1 4 1c1.6 0 2.719 1.05 3.404 2.008.26.365.458.716.596.992a7.55 7.55 0 0 1 .596-.992C9.281 2.049 10.4 1 12 1c2.21 0 4 1.755 4 3.92 0 2.069-1.3 3.288-3.365 5.227-1.193 1.12-2.642 2.48-4.243 4.38z" />
                </svg>
              </span>
              <br />
            </div>
            <div>
              <span
                style={{
                  marginRight: "5px",
                  fontSize: "14px",
                  color: "gainsboro",
                }}
              >
                30 Days ago
              </span>
              <span style={{ fontSize: "14px", color: "gainsboro" }}>
                354 Likes
              </span>
            </div>
          </div>
        </MainDesc>
      </HeadDiv>
    );
  };
  return (
    <Main>
      <Tab>
        <Item className={active.edit} onClick={() => handleTabItem("edit")}>
          Users
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-person-fill"
            viewBox="0 0 16 16"
          >
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          </svg>
        </Item>
        <Item
          className={active.profile}
          onClick={() => handleTabItem("profile")}
        >
          Tags
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-tag-fill"
            viewBox="0 0 16 16"
          >
            <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        </Item>
        <Item
          className={active.notifictaion}
          onClick={() => handleTabItem("notifictaion")}
        >
          Location
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-geo-alt-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
          </svg>
        </Item>
      </Tab>
      <Container>
        {active.edit === "active" ? (
          <>
            <HeadDiv>
              <MainDesc>
                <ProfileImg
                  style={{
                    backgroundImage: `url("https://d1zjvcsgfckfe5.cloudfront.net/crypto/1639869886407-Screenshot-20211218-221757-2.png")`,
                  }}
                ></ProfileImg>
                <UserDiv>
                  <UserSpan>
                    <UserName>woodburning</UserName>
                  </UserSpan>
                  <ProfileDesc> Turkey</ProfileDesc>
                </UserDiv>
                <div>
                  <div>
                    <span style={{ float: "right" }}>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-three-dots"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                      </svg>
                    </span>
                    <span style={{ float: "right", marginRight: "5px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-suit-heart"
                        viewBox="0 0 16 16"
                      >
                        <path d="m8 6.236-.894-1.789c-.222-.443-.607-1.08-1.152-1.595C5.418 2.345 4.776 2 4 2 2.324 2 1 3.326 1 4.92c0 1.211.554 2.066 1.868 3.37.337.334.721.695 1.146 1.093C5.122 10.423 6.5 11.717 8 13.447c1.5-1.73 2.878-3.024 3.986-4.064.425-.398.81-.76 1.146-1.093C14.446 6.986 15 6.131 15 4.92 15 3.326 13.676 2 12 2c-.777 0-1.418.345-1.954.852-.545.515-.93 1.152-1.152 1.595L8 6.236zm.392 8.292a.513.513 0 0 1-.784 0c-1.601-1.902-3.05-3.262-4.243-4.381C1.3 8.208 0 6.989 0 4.92 0 2.755 1.79 1 4 1c1.6 0 2.719 1.05 3.404 2.008.26.365.458.716.596.992a7.55 7.55 0 0 1 .596-.992C9.281 2.049 10.4 1 12 1c2.21 0 4 1.755 4 3.92 0 2.069-1.3 3.288-3.365 5.227-1.193 1.12-2.642 2.48-4.243 4.38z" />
                      </svg>
                    </span>
                    <br />
                  </div>
                  <div>
                    <span
                      style={{
                        marginRight: "5px",
                        fontSize: "14px",
                        color: "gainsboro",
                      }}
                    >
                      30 Days ago
                    </span>
                    <span style={{ fontSize: "14px", color: "gainsboro" }}>
                      354 Likes
                    </span>
                  </div>
                </div>
              </MainDesc>
            </HeadDiv>
          </>
        ) : active.profile === "active" ? (
          // <ProfileVerification />
          "2"
        ) : (
          // <Notification />
          "3"
        )}
      </Container>
    </Main>
  );
}

export default SearchTab;
