import {
  getUserApi,
  getUserNameApi,
  getMessageApi,
  twitterLoginApi,
  updateUserApi,
  profilePostApi,
  allPostsApi,
  validateLinkApi,
  deleteProfileApi,
  uploadProfileApi,
  metaDataApi,
  createAssetsApi,
} from "../../services/api";
import { toast } from "react-toastify";
import { apiHandler } from "services/axios";
import { loginApi } from "services/loginServices";
import { setLoginState, setLoginToken } from "store/loginStore/actionCreator";
import { setUserData } from "store/userStore/actionCreators";
// import { toast } from "react-toastify";

export const setValidateUrl = (data) => {
  return {
    type: "SETVALIDATEURL",
    data,
  };
};
export const setUserDetails = (data) => {
  return {
    type: "SETUSERADDRESS",
    data,
  };
};

export const setLink = (data) => {
  return {
    type: "SETLINK",
    data,
  };
};
export const setUIDLink = (data) => {
  return {
    type: "SETUID",
    data,
  };
};
export const setUserNameAvalability = (data) => {
  return {
    type: "USERNAMEAVALABLE",
    data,
  };
};
export const setMessage = (data) => {
  return {
    type: "SETMESSAGE",
    data,
  };
};
export const setAddresses = (address1, address2) => {
  return {
    type: "SETADDRESS",
    address1,
    address2,
  };
};
export const setLoginData = (data) => {
  return {
    type: "SETLOGINDATA",
    data,
  };
};

export const setAssects = (data) => {
  return {
    type: "SETASSETS",
    data,
  };
};
export const clearData = () => {
  return {
    type: "DISCONNECT",
  };
};

export const validateLink = (url, token) => async (dispatch) => {
  console.log("uploadProfile action");
  dispatch(setValidateUrl(url));
  try {
    const response = await validateLinkApi(url, token);
    console.log(response);
    dispatch(setLink(response.data));
    toast(response.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (response.status == "success") {
    } else {
      // toast.error(response.Message);
    }
  } catch (e) {
    console.log(e);
  }
};
export const deleteProfile = (token) => async (dispatch) => {
  console.log("uploadProfile action");
  try {
    const response = await deleteProfileApi(token);
    console.log(response);
    if (response.status == "success") {
      // toast.success(response.Message);
      // dispatch(setUserDetails(response));
    } else {
      // toast.error(response.Message);
    }
  } catch (e) {
    console.log(e);
  }
};
export const uploadProfile = (data, token) => async (dispatch) => {
  console.log("uploadProfile action");
  try {
    const response = await uploadProfileApi(data, token);
    console.log(response);
    if (response.status == "success") {
      // toast.success(response.Message);
      // dispatch(setUserDetails(response));
    } else {
      // toast.error(response.Message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const createAssets = (data, token, UID) => async (dispatch) => {
  try {
    const response = await createAssetsApi(data, token, UID);
    console.log(response);
    if (response.data.asset) {
      toast("Asset Create Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // toast.success(response.Message);
      // dispatch(setUserDetails(response));
    } else {
      // toast.error(response.Message);
    }
  } catch (e) {
    console.log(e);
  }
};
export const updateUser = (data, token) => async (dispatch) => {
  try {
    const response = await updateUserApi(data, token);
    console.log(response);
    if (response.status == "success") {
      // toast.success(response.Message);
      // dispatch(setUserDetails(response));
    } else {
      // toast.error(response.Message);
    }
  } catch (e) {
    console.log(e);
  }
};
export const profilePost = (token) => async (dispatch) => {
  try {
    const response = await profilePostApi(token);
    console.log(response.data, "home page data");
    if (response?.data) {
      dispatch(setAssects(response.data));
    }
  } catch (e) {
    console.log(e);
  }
};
export const allPosts = (token) => async (dispatch) => {
  try {
    const response = await allPostsApi(token);
    console.log(response);
    if (response.status == "success") {
      // toast.success(response.Message);
      // dispatch(setUserDetails(response));
    } else {
      // toast.error(response.Message);
    }
  } catch (e) {
    console.log(e);
  }
};
export const twitterLogin = (token) => async (dispatch) => {
  try {
    const response = await twitterLoginApi(token);
    console.log(response);
    if (response.status == "success") {
      // toast.success(response.Message);
      // dispatch(setUserDetails(response));
    } else {
      // toast.error(response.Message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const metaData = (buccket_link, token) => async (dispatch) => {
  try {
    const response = await metaDataApi(buccket_link, token);
    console.log(response);
    dispatch(setUIDLink(response.data));
    // toast("Content uploaded on IPFS successfully", {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
  } catch (e) {
    console.log(e);
  }
};
export const getUserByWalletAddres = (data) => async (dispatch) => {
  try {
    const response = await getUserApi(data);
    console.log(response);
    if (response.status == "success") {
      // toast.success(response.Message);
      dispatch(setUserDetails(response));
    }
    if (response.status == "error") {
      // toast.success(response.Message);
      dispatch(setUserDetails(response));
    } else {
      // toast.error(response.Message);
    }
  } catch (e) {
    console.log(e);
  }
};
export const getMessage = (data) => async (dispatch) => {
  try {
    const response = await getMessageApi(data);
    console.log(response);
    if (response.status == "success") {
      // toast.success(response.Message);
      dispatch(setMessage(response.data.signinmessage));
    } else {
      // toast.error(response.Message);
    }
  } catch (e) {
    console.log(e);
  }
};
export const login =
  (username, name, signature, message, address) => async (dispatch) => {
    try {
      const response = await loginApi(
        username,
        name,
        signature,
        message,
        address
      );

      console.log(response, "login data");
      if (response.data.status == "success") {
        dispatch(setLoginData(response));
      }
      if (response.status == "input_error") {
        toast.error(response.message);
      } else {
        // toast.error(response.Message);
      }
    } catch (e) {
      console.log(e);
    }
  };
export const checkusername = (data) => async (dispatch) => {
  try {
    const response = await getUserNameApi(data);
    console.log(response);
    if (response.status == "error") {
      // toast.success(response.Message);
      dispatch(setUserNameAvalability(response));
    } else {
      // toast.error(response.Message);
    }
  } catch (e) {
    console.log(e);
  }
};
