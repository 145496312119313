import styled from "styled-components";

export const Main = styled.div`
  over-flow: visible;
  position: inherit;
  background: white;
  width: 20rem;
  overflow: visible;
  margin-top: 2rem;
  float: right;
  padding-top: 0.5rem;
  .active {
    color: blue;
    border-bottom: 2px solid blue;
  }
`;
export const Tab = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
  // border-bottom: 2px solid gray;
`;
export const Item = styled.div`
  cursor: pointer;
  font-size: 0.9rem;
  :hover {
    color: blue;
    border-bottom: 2px solid blue;
  }
  .active {
    color: blue;
    border-bottom: 2px solid blue;
  }
  :focus-visible {
    color: blue;
  }
`;
export const Container = styled.div`
  padding: 1rem;
  padding-top: 0rem;
`;

export const ImgDiv = styled.div`
  text-align: center;
  margin-top: 1.25rem;
`;
export const Img = styled.img`
  width: 7rem;
  height: 7rem;
  border-radius: 20rem;
  margin-right: -2.5rem;
`;
export const Button = styled.button`
  background: none;
  border-radius: 1.563rem;
  height: 1.875rem;
  font-size: 0.75rem;
  margin-left: 0.625rem;
`;
export const HeadDiv = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  margin: 0.625rem;
`;

export const TabItem = styled.div`
  font-size: 0.813rem;
  font-weight: 600;
  margin-left: 0.375rem;
  margin-right: 0.375rem;
  :active {
    background: black;
  }
`;
export const InputLable = styled.label`
  background-color: #eff3f4;
  // margin-left:1.25rem;
  height: 1.875rem;
  font-size: 0.875rem;
  border-radius: 1.25rem;
  width: fit-content;
  padding: 0.313rem;
  width: 70%;
  padding-left: 0.625rem;
`;
export const Input = styled.input`
outline:none;
background-color: #eff3f4;
font-size: 0.875rem;
touch-action: manipulation;
    border:none;
}

`;
export const InputImage = styled.img`
vertical-align: middle;
border-style: none;
:focus-visible{
    outline: none;
}
}
`;

export const ProfileDesc = styled.span`
  // font-family: Inter, sans-serif;
  font-size: 0.625rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.3px;
  text-align: left;
  color: #5a5a63;
  display: block;
  margin-left: 0.813rem;
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.15s ease-in;
`;
export const UserName = styled.span`
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.15s ease-in;
`;
export const LogoImg = styled.img`
  width: 0.75rem;
  margin-right: 0.438rem;
`;
export const UserSpan = styled.span`
  // font-family: Inter, sans-serif;
  font-size: 0.813rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.3px;
  text-align: left;
  color: #2c2a38;
  margin-bottom: 0.188rem;
  width: 100%;
  display: flex;
  margin-left: 0.813rem;
`;
export const UserDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 2rem);
`;
export const MainDesc = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
export const ProfileImg = styled.div`
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  // width: 25px;
  border-radius: 50%;
  position: relative;
  background: url(https://app.nafter.io/static/media/defaultAvatar.2bd8b1b6.svg)
    no-repeat 50% / cover;
`;
export const Follower = styled.span`
  // font-family: Inter, sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2c2a38;
  height: 0.938rem;
`;

export const ButtonBG = styled.button`
margin-left:0.625rem;
float: right;
// margin-right:1.875rem;
text-align:center;
background: linear-gradient(90deg, #02212C -2.59%, #00B0F0 160.54%), linear-gradient(96.76deg, #FFA17F -44.56%, #00223E 189.36%), #C4C4C4;
color:#FFFFFF;
        // font-family: Inter,sans-serif;
        font-weight: 700;
        flex-shrink: 0;
        // width: fit-content!important;
                height: 1.875rem;
        border-radius: 1.063rem;
        line-height: 0.938rem;
        color: #fff;
    font-size: 0.75rem;
    // width:10%;
    border:none;
}
`;
export const Desc = styled.p``;
export const CommentDiv = styled.div`
  display: flex;
  margin-top: 1.2rem;
`;
