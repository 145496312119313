import getAxiosInst from "./axios";

const API_SUB_ENDPOINT = "/auth";

export const loginApi = ({ username, name, signature, message }) => {
  var data = {
    signature: signature,
    message: message,
    username: username,
    name: name,
    // publicaddress: address,
  };
  return getAxiosInst().post(`${API_SUB_ENDPOINT}/login`, data);
};

export const getMessageApi = () => {
  return getAxiosInst().get(`${API_SUB_ENDPOINT}/message`);
};
